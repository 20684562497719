.complex-table {
    border-collapse: collapse;
    border-radius: 8px;
    box-shadow: 0 1px 11px 2px rgba(16, 24, 40, .1), 0 1px 3px 0 rgba(16, 24, 40, .1);
    margin: 0 auto 16px;
    overflow: hidden;
    width: 100%;

    tr {
        border-bottom: 1px solid #eaecf0;
    }

    th,
    td {
        font-size: 13px;
        padding: 16px;
        text-align: left;
        vertical-align: top;
    }

    th {
        background-color: #fff;
        color: #777;
        font-weight: bold;
    }

    tbody {
        tr:nth-child(odd) {
            background-color: #f9fafb;
        }
    }
}

.complex-table-container {
    background: #f9fafb;
    margin-bottom: 80px;
    overflow: hidden;
    padding: 32px 32px 16px;
    width: 100%;

    .product-sheet-boxes__col & {
        margin-bottom: 0;
    }

    h3 {
        color: #999;
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 16px;
        text-transform: uppercase;
    }

    &__list {
        list-style: none;
        strong {
            display: block;
        }
    }

    &__columns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 74px;
        margin-bottom:16px;

        li {
            border-top: 1px solid rgb(216, 216, 216);
            padding: 12px 0;

            &:first-child {
                border-top: none;
            }
        }
    }
}